<template>
  <div>
    <AppHeader v-if="!isMobile">
      <template v-slot:left-action>
        <close class="close" @click.prevent="onClose" />
      </template>

      <template v-slot:title>
        <h1>{{ title }}</h1>
      </template>
    </AppHeader>

    <main :style="{ paddingBottom: footerHeight + 'px' }">
      <slot />
    </main>

    <Errors :errors="errors" />
    <Spinner v-if="loading" />

    <footer ref="footer">
      <div class="container">
        <slot name="footer-buttons" />

        <a class="btn btn-primary btn-green" @click.prevent="onClose">
          {{ finishText || $t("buttonActions.finish") }}
        </a>

        <slot name="footer-buttons-confirm" />
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Close from "vue-material-design-icons/Close.vue";
import { setActualFooterHeight } from "@/mixins/footer";
import { getParams } from "@/mixins/platform-params.js";
import AppHeader from "@/components/AppHeader";
import Errors from "@/components/Errors";
import Spinner from "@/components/Spinner";

export default {
  name: "ScannedListLayout",
  mixins: [setActualFooterHeight, getParams],
  components: {
    AppHeader,
    Close,
    Errors,
    Spinner
  },
  props: {
    onClose: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    finishText: {
      type: String
    }
  },

  computed: {
    ...mapState(["errors", "loading"])
  }
};
</script>

<style lang="scss" scoped>
footer {
  a:not(:last-child) {
    margin-bottom: 10px;
  }
}
</style>
